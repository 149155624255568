import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import arrow from "../img/arrowLeft.svg"

import LayoutKo from "../components/layout"
import LayoutEn from "../components/layoutEn"

export default function NewsPostTemplate({ data, pageContext, location }) {
  const post = data.markdownRemark
  const Layout = post.fields.lang === "en" ? LayoutEn : LayoutKo
  const link = post.fields.lang === "en" ? "/PressEn/" : "/Press/"

  return (
    <Layout>
      <div className="postTitleBox">
        <Link to={link} className="backBtn">
          <i>
            <img src={arrow} alt="" />
          </i>
          news &amp; press list
        </Link>
        <h1>{post.frontmatter.title}</h1>
        <p className="postTypeBox">{post.frontmatter.type}</p>
        <p className="postDateBox">{post.frontmatter.date}</p>
        <p className="postImgBox">
          <Img fluid={post.frontmatter.image.childImageSharp.fluid} />
        </p>
        <div
          className="postContentBox"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        lang
      }
      frontmatter {
        title
        type
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
